import { useEffect, useRef, useState } from 'react'

// Use to detect scroll direction within an Container (div)
const useScrollDirectionForContainer = () => {
  const mainRef = useRef<HTMLDivElement>(null)
  const [scrollDirection, setScrollDirection] = useState<'down' | 'up'>()

  useEffect(() => {
    const mainRefCurrent = mainRef.current
    if (!mainRefCurrent) return
    let prevScrollY = mainRefCurrent.scrollTop

    const updateScrollDirection = () => {
      const scrollY = mainRefCurrent.scrollTop
      const newScrollDirection = scrollY > prevScrollY ? 'down' : 'up'
      setScrollDirection((scrollDirection) => {
        if (Math.abs(scrollY - prevScrollY) > 10) return newScrollDirection
        return scrollDirection
      })

      prevScrollY = scrollY > 0 ? scrollY : 0
    }

    mainRefCurrent.addEventListener('scroll', updateScrollDirection) // add event listener
    return () => {
      mainRefCurrent.removeEventListener('scroll', updateScrollDirection) // clean up
    }
  }, [])

  return { mainRef, scrollDirection }
}

export default useScrollDirectionForContainer
