import { NextPageWithOptions } from 'shared/types/App'
import { withSessionSsr } from 'lib/session'
import OnboardingLayout from 'shared/components/onboarding/OnboardingLayout'
import SignInView from 'views/authentication/components/SignInView'
import Metadata from 'shared/components/Metadata'

export const getServerSideProps = withSessionSsr(async (context) => {
  const { isLoggedIn } = context.req
  if (isLoggedIn) return { redirect: { destination: '/', permanent: false } }
  return { props: {} }
})

const LoginPage: NextPageWithOptions = () => {
  return (
    <>
      <Metadata title="Login" url="/login" />
      <OnboardingLayout>
        <SignInView mode="page" />
      </OnboardingLayout>
    </>
  )
}

export default LoginPage
