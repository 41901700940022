import cn from 'classnames'
import { ReactNode } from 'react'

type Props = {
  className?: string
  children: ReactNode
  variant?: 'default' | 'android-coming-soon'
}

const BackgroundTemplate = ({ className, children, variant = 'default' }: Props) => (
  <div
    className={cn(
      'relative h-screen w-screen bg-cover bg-center bg-no-repeat bg-origin-border font-monument-grotes',
      className,
      variant === 'default' && 'bg-[url(/images/onboarding/background.webp)]',
    )}
  >
    {variant === 'default' && (
      <>
        <div className="absolute z-0 h-full w-full bg-[#000000B3]" />
        <div className="absolute -left-[5%] top-[60%] z-10 flex rotate-[15deg] flex-col items-center text-[48px] uppercase leading-[60px] text-white sm:top-[40%] lg:text-[125px] lg:leading-[156px] 3xl:text-[250px] 3xl:leading-[260px]">
          <h1>Join the</h1>
          <h1>New Art</h1>
          <h1>World</h1>
        </div>
        <div className="absolute -right-[6%] top-[13%] z-10 flex -rotate-[15deg] flex-col items-center text-[48px] uppercase leading-[60px] text-white sm:right-[2%] sm:top-[20%] lg:text-[125px] lg:leading-[156px] 3xl:right-0 3xl:text-[250px] 3xl:leading-[260px]">
          <h1>Join the</h1>
          <h1>New Art</h1>
          <h1>World</h1>
        </div>
      </>
    )}

    {children}
  </div>
)

export default BackgroundTemplate
