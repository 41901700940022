import { XMarkIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import BackgroundTemplate from 'shared/components/layout/BackgroundTemplate'
import Header from '@shared/components/layout/Header'
import useScrollDirectionForContainer from 'hooks/useScrollDirectionForContainer'
import Link from 'next/link'

type OnboardingLayoutProps = {
  children: React.ReactNode
  isCloseable?: boolean
  variant?: 'default' | 'android-coming-soon'
}

const OnboardingLayout = ({ children, isCloseable, variant = 'default' }: OnboardingLayoutProps) => {
  const { mainRef } = useScrollDirectionForContainer()

  return (
    <>
      <Header isTransparent={true} />
      <BackgroundTemplate className="no-scrollbar overflow-x-hidden" variant={variant}>
        {/* content */}
        <div
          className="no-scrollbar fixed left-0 top-0 z-20 flex h-full w-full flex-col overflow-y-scroll bg-black/20 py-[100px] lg:justify-center lg:bg-transparent"
          ref={mainRef}
        >
          <div
            className={cn(
              'container flex flex-col items-center justify-center px-4 lg:flex-row',
              variant === 'android-coming-soon' && 'mt-[150px] lg:mt-0', // 🥴🥴🥴
            )}
          >
            <div className="z-50 rounded-[15px] bg-white px-[32px] py-[26px] lg:mt-10 lg:rounded-[32px] lg:px-[50px] lg:py-[40px]">
              <div className="h-full w-full font-inter">
                {isCloseable && (
                  <div className="flex w-full justify-end">
                    <Link passHref href="/">
                      <XMarkIcon className="h-6 w-6 lg:h-10 lg:w-10" />
                    </Link>
                  </div>
                )}
                <div className="flex h-full w-full flex-col items-center gap-5 ">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundTemplate>
    </>
  )
}

export default OnboardingLayout
